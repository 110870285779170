
/* TEST DEVELOPMENT
const SUPABASE_CONFIG = {
  supabase_url: "https://tpzgvkcovehpjoxzkske.supabase.co",
  supabase_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRwemd2a2NvdmVocGpveHprc2tlIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODYxMzM0OTQsImV4cCI6MjAwMTcwOTQ5NH0.KseyUZ1plreVwBtrnQubCXGJOpvgtbrCQ8T86V4QjXU",
};
*/

const SUPABASE_CONFIG = {
  supabase_url: "https://ldbsfknvcunezzzgmvbe.supabase.co",
  supabase_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxkYnNma252Y3VuZXp6emdtdmJlIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODYxOTM3MzIsImV4cCI6MjAwMTc2OTczMn0.AsXvNJo8RUSsMz6FRksPhqfBTjGLdShvPA3LUui5y_E",
  supabase_storage: "https://ldbsfknvcunezzzgmvbe.supabase.co/storage/v1/object/public/",
};

export default SUPABASE_CONFIG 